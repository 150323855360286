import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Editor } from '@tinymce/tinymce-react';



import { AddSettings,getSettings,UpdateSettings } from '../../actions/admin';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  textDanger: {
    color: "#f32828",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  "_id":"",
  'linkedin': "",
  'facebook': "",
  'twitter': "",
  "medium":"",
  "youtube":"",
  "twitch":"",
  "github":"",
  "startTimeStamp":''
  
}

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
 

 
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [settingsdata, setsettingsdata] = useState(new Date());
  const { userId } = useParams();

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    // console.log(formValue);
  }

  const {
    linkedin,
    facebook,
    twitter,
    medium,
    youtube,
    twitch,
    github,
    _id,
    startTimeStamp,
  } = formValue

  

 
  const startdatechange = async (event) => {
    console.log(event, "=======================")
    setStartDate(event)
    var data = {
      startdate: event,
     
    }
  }
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let reqData = {
        startDate,
        facebook,
        linkedin,
        twitter,
        medium,
        youtube,
        twitch,
        github,
        _id,
        startTimeStamp
      }
      console.log(reqData,'reqData')

if(_id!=''){
  const { status, loading, messages, error } = await UpdateSettings(reqData);
  if (status == 'success') {
    toastAlert('success', messages, 'AddCms')
  
    setValidateError({})
  
  } else {
    setValidateError(error);
  }
} else{
  const { status, loading, messages, error } = await AddSettings(reqData);
  console.log(error,'errorerror')
 
  if (status == 'success') {
    toastAlert('success', messages, 'AddCms')
  history.push("/SpideyBarter-NFT/SpideyLottAdmin/dashboard")
    setValidateError({})
  
  } else {
    setValidateError(error);
  }
}
      
    } catch (err) {
      console.log(err, 'errr')
    }

  }
  const getsettingsData=async ()=>{

    const data=await getSettings();
    console.log(data,'datattat')
    if(data&&data.result&&data.result.data&&data.result.data.data){
      setFormValue(data.result.data.data)
    }
    else{
      setFormValue(initialFormValue)
    }
   


  }
  useEffect(()=>{

    getsettingsData();

  },[])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <p style={{display:"none"}}>{_id}</p>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New CMS</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
             
                <strong>Countdown Date:</strong>
              
                  {/* <DatePicker selected={startDate} onChange={startdatechange} className="mr-4" /> */}
                  <DatePicker
                  selected={startTimeStamp}
                  onChange={(date) => {
                    let newDate = new Date(date)
                    newDate.setMilliseconds(0)
                    let formData = {
                      ...formValue,
                      startTimeStamp: newDate.getTime(),
                    }
                    setFormValue(formData)
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                
 <CustomInput
   labelText="LinkedIn"
   id="linkedin"
   value={linkedin}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.linkedin && <span className={classes.textDanger}>{validateError.linkedin}</span>
 }

                    
 <CustomInput
   labelText="Facebook"
   id="facebook"
   value={facebook}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.facebook && <span className={classes.textDanger}>{validateError.facebook}</span>
 }          
   <CustomInput
   labelText="Twitter"
   id="twitter"
   value={twitter}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.twitter && <span className={classes.textDanger}>{validateError.twitter}</span>
 }                
 <CustomInput
   labelText="Medium"
   id="medium"
   value={medium}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.medium && <span className={classes.textDanger}>{validateError.medium}</span>
 }                
 <CustomInput
   labelText="Youtube"
   id="youtube"
   value={youtube}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.youtube && <span className={classes.textDanger}>{validateError.youtube}</span>
 }                
<CustomInput
   labelText="Twitchtv"
   id="twitch"
   value={twitch}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.twitch && <span className={classes.textDanger}>{validateError.twitch}</span>
 }   
 <CustomInput
   labelText="Github"
   id="github"
   value={github}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   
  
 />
 {
   validateError.github && <span className={classes.textDanger}>{validateError.github}</span>
 }   
                </GridContainer>
              


              </CardBody>
              <CardFooter>
                {!_id&&(
 <Button color="primary" type="submit">Add</Button>
                )}
                             {_id&&(
 <Button color="primary" type="submit">Update</Button>
                )}
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
