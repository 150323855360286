import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import isEmpty from '../../lib/isEmpty';
import { getcatory, getDonationdata, updatecategory } from '../../actions/admin';
import ABI from '../../ABI/ABI.json';
import Fortmatic from 'fortmatic';
import Select from "react-select";
import config from '../../lib/config'
import Web3 from 'web3';
import '@metamask/legacy-web3';
const { web3 } = window;
const Smartcontract = config.Smartcontract;
const OwnerAddr = config.OwnerAddr;
const useStyles = makeStyles(styles);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

// toaster config
toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const initialFormValue = {
    'tokenCounts': "",
    'tokenName': "",
    'image': "",
    'tokenDesc': "",
    'tokenBid': "",
    'tokenPrice': "",
    'tokenRoyality': "",
    'tokenProperty': "",
    'tokenCategory': "",
    'tokenOwner':"",
    'Photofile':"",
    'tokenCreator':"",
}

export default function DonationdView() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [userdet, setUser] = useState();
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [accounts, setaccount] = React.useState(0);
    const [tokenbalance, setTokenbalance] = React.useState(0);
    const [bnbbalance, setBNBbalance] = React.useState(0);
    const [categoryurl, setImage] = React.useState("");
    const [category, setCategory] = useState([]);
    const [categoryname, setCategoryname] = useState('');
    const [catdata, setcatdata] = useState('');

    const [selectedOption, setselectedOption] = useState(null);
    const { userId } = useParams();

    useEffect(() => {
        getDonationdlist();
    }, [])


    const handleChange = (optionsTerms) => {
        console.log("handleChange", optionsTerms)
        setCategoryname({ categoryname: optionsTerms.value });
    };
    const getDonationdlist = async () => {
        var test = await getDonationdata(userId);
        console.log("userValue111", test.cmsData)
        if (test&&test.cmsData!=undefined) {
               let formdata = {};
               formdata['airdrop'] = test.cmsData.airdrop;
               formdata['blockchange'] = test.cmsData.blockchange;
               formdata['curraddress'] = test.cmsData.curraddress;
               formdata['currency'] = test.cmsData.currency;
               formdata['donation'] = test.cmsData.donation;
               formdata['project'] = test.cmsData.project;
               formdata['giveaway'] = test.cmsData.giveaway;
               formdata['txid'] = test.cmsData.txid;
             setFormValue(formdata)
        }
      
    }


    const handleFile = (event) => {
        event.preventDefault();
        const { id, files } = event.target;
        let formData = { ...formValue, ...{ [id]: files[0] } }
        setFormValue(formData)
    };
 const back = async () => {
     window.location.href='/spideywqwedkk/ponzi';
    }
    const onChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } }
        setFormValue(formData)
    }

    const {
        airdrop,
        blockchange,
        curraddress,
        currency,
        donation,
        project,
        giveaway,
        txid

    } = formValue

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // var name = categoryname.categoryname;/
        // let reqData = {
        //     name,
        //     Photofile,
        //     userId
        // }

        // console.log("updatecategory", reqData)
        // const { error } = await updatecategory(reqData);
        // if (isEmpty(error)) {
        //     toast.success('Category details updated successfully', toasterOption);
        //     setTimeout(
        //         () => window.location.href = '/sadsadsadsadada/TokenList'
        //         , 1000)

        // } else {
        //     setValidateError(error);
        // }

    }

    return (
        <div>
        <div className="page_header">
         <button className="btn btn-success mr-3" onClick={() => back()}>Back</button> 
      </div>
            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>View Cheated</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <label>Project name</label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="project"
                                            value={project}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                    <label>Currency</label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="currency"

                                            value={currency}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                             inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                    <label>Amount</label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="donation"
                                            value={donation}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                             inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                    <label>Transaction Id</label>
                                        <label>txid</label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="txid"
                                            value={txid}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                             inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                              
                                  
                                    <GridItem xs={12} sm={12} md={3}>
                                    <label>User Address</label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="curraddress"

                                            value={curraddress}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                             inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                    <label>Air Drop </label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="airdrop"

                                            value={airdrop}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                             inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                    <label>Give Away </label>
                                        <CustomInput
                                            onChange={onChange}
                                            id="giveaway"

                                            value={giveaway}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                             inputProps={{
                                              disabled: true
                                          }}
                                        />

                                    </GridItem>
                                  
                                
                                </GridContainer>

                            </CardBody>
                            {/* <CardFooter>
                                <Button color="primary" type="submit">Update</Button>
                            </CardFooter>*/}
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
