// import package
import axios from "axios";

// import lib
import config from "../lib/config";

// import constant
import { SET_CURRENT_USER } from "../constant";

export const login = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/login`,
      data,
    });

    localStorage.setItem("admin_token", respData.data.token);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    console.log("adminlogin", err.response);
    return {
      loading: false,
      error: err.response.data,
    };
  }
};

export const addLotterySettings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/addLotterySettings`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addLottery = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/addLottery`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAddress = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getAddress`,
    });
    return {
      loading: false,
      userValue: respData.data.addressData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getlotterylist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getlotterylist`,
    });
    return {
      loading: false,
      userValue: respData.data.addressData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const completeTicket = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/completeTicket`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const closeComTicket = async (data, dispatch) => {
  console.log("yessaran");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/closeComTicket`,
      data,
    });

    console.log(respData, "respDatarespDatarespDatarespDatarespData");
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const halfAddrShow = (addr) => {
  if (addr) {
    return addr.substring(0, 5) + "...." + addr.slice(addr.length - 5);
  } else {
    return "";
  }
};
export const updateProfile = async (data) => {
  // console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("mobilenumber", data.mobilenumber);
    bodyFormData.append("photo", data.photo);
    bodyFormData.append("company", data.company);
    bodyFormData.append("designation", data.designation);
    bodyFormData.append("detail", data.detail);
    //console.log(bodyFormData,"fdsfdsfsdfsdfsdfsdfsdfdsfsdfdsfdsfsdfsdfsdfsdfsdfsdfsdfsdf8888888888888888888888");
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateProfile`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateSettings = async (data) => {
  // console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateSettings`,
      data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const accept = async (data, dispatch) => {
  console.log("data!!!!!!!!:", data);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/accept/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    console.log("accept:", respData.data);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getemailTemplateList = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getemailTemplateList`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getcatorytype = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcatorytype`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      userValue: respData.data.contactData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getverificationlist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getverificationlist`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      userValue: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getemailTemplate = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getemailTemplate/` + id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });

    console.log(respData.data);
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateEmailTemplate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateEmailTemplate`,
      data,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const reject = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/reject/`,
      headers: {
        Authorization: localStorage.user_token,
      },
      data,
    });
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateuser = async (data) => {
  //console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("phonenumber", data.phonenumber);
    bodyFormData.append("address1", data.address1);
    bodyFormData.append("address2", data.address2);
    bodyFormData.append("pincode", data.pincode);
    bodyFormData.append("city", data.city);
    bodyFormData.append("country", data.country);
    bodyFormData.append("Photofile", data.Photofile);
    bodyFormData.append("userId", data.userId);
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateuser`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcms = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcms/` + id,
      headers: {
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcmslist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcmslist`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updatecms = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatecms`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addpair = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addpair`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const addcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addcategory`,
      data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const gettoken = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/gettoken`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const addtoken = async (data) => {
  console.log("#########", data);
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("tokencount", data.tokencount);
    bodyFormData.append("price", data.price);
    bodyFormData.append("tokenroyality", data.tokenroyality);
    bodyFormData.append("tokendesc", data.tokendesc);
    bodyFormData.append("Photofile", data.Photofile);

    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addtoken`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const addcategorydet = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("categoryname", data.cname);
    bodyFormData.append("tokenname", data.tname);
    bodyFormData.append("Photofile", data.Photofile);
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/addcategorydet`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const gettokenlist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/gettokenlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData,
    };
    console.log("ok");
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
    console.log("not ok");
  }
};
export const updatecategory = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("Photofile", data.Photofile);
    bodyFormData.append("userId", data.userId);

    console.log(bodyFormData, "updatecategory");
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatecategory`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const adduser = async (data) => {
  console.log(data, "dataaaaaaaaaaaaaaaaaaaa");
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("phonenumber", data.phonenumber);
    bodyFormData.append("address1", data.address1);
    bodyFormData.append("address2", data.address2);
    bodyFormData.append("pincode", data.pincode);
    bodyFormData.append("city", data.city);
    bodyFormData.append("country", data.country);
    bodyFormData.append("Photofile", data.Photofile);
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/adduser`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.admin_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addfaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/addfaq`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    console.log("aaaaaaaaaaaaaaaa", err.response.data.errors);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updatepair = async (data) => {
  console.log(data, "data");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatepair`,

      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updatefaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/updateFaq`,

      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCheatedlist = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getCheatedlist`,
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    console.log("adminlogin", err.response);
    return {
      loading: false,
      error: err.response.data,
    };
  }
};

export const getDonationdata = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getDonationdata/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getFeedbacklist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getfeedbacklist`,
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getDonationlist = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getDonationlist/`,
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getScancompanylist = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getScancompanylist/`,
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateWithdraw = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateWithdraw`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteCheated = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/deleteCheated`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deleteDonate = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/deleteDonate`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deleteScancompany = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/deleteScancompany`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getadmintransaction = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getadmintransaction/`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const get2faadmin = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/get2faadmin/`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getadminwithdraw = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getadminwithdraw/`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuser = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuser/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaq = async (id, dispatch) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getFaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteuser = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deleteuser/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletepair = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletepair/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletecategory = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletecategory/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteToken = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/deleteToken/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getCmsList = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getCmsList`,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData.data.cmsData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getCmsData = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getCmsData/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData.data.cmsData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updateCmsData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updateCmsData`,
      data,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getContactUs = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getContactUs`,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getContactDetails = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getContactDetails/` + id,
    });

    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const updatecontact = async (data) => {
  console.log(data, "data");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/updatecontact`,

      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    console.log("err", err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const adminReplay = async (data, id) => {
  console.log("yes", data);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/adminReplay/` + id,
      data,
    });
    return {
      data: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletesupportchat = async (id, data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletesupportchat/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getChats = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getChats/` + id,
    });
    return {
      getChats: respData.data.ticketDatas[0].reply,
      ticket_id: respData.data.ticketDatas[0]._id,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletesupport = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletesupport/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getSupportList = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getSupportList`,
    });
    console.log("supportData", respData);
    return {
      loading: false,
      supportDetails: respData.data.supportData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deletecontact = async (id, dispatch) => {
  console.log("deletecontact", id);
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/deletecontact/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const deletfaq = async (id, dispatch) => {
  //console.log('yes');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/deleteFaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getsettdata = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getsettdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getdonationcount = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getdonationcount`,
    });
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const gettokencount = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getdonationcount1`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getbidderscount = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getbidderscount`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.countData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getusermonthly = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getusermonthly`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdepositmonthly = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getdepositmonthly`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getwithdrawmonthly = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getwithdrawmonthly`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuserdata = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuserdata`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getpairdata = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getpairdata/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getcategorydata = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcategorydata/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

//added
export const gettokendata = async (id) => {
  console.log("yes", id);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/gettokendata/` + id,
    });

    console.log(respData, "RESdATA");

    return {
      loading: false,
      cmsData: respData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuserlist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuserlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getBidslist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getBidslist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getpairlist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getpairlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getcatorylist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcatorylist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getcatory = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcatory`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getuserlisttrans = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getuserlisttrans`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcsvdata = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getcsvdata`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getuserdaily = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getuserdaily`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdepositdaily = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getdepositdaily`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getwithdrawdaily = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getwithdrawdaily`,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdepositlist = async (filterData, dispatch) => {
  //  console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getdepositlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getwithdrawlist = async (filterData, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/admin/getwithdrawlist`,
      params: filterData,
    });
    console.log();
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const stautuchange = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/stautuchange`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getusercustom = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getusercustom`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdepositcustom = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getdepositcustom`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getwithdrawcustom = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/getwithdrawcustom`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getfaqlist = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getfaqlist`,
      params: data,
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCurrentUser = async (token, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API.userService}/api/currentUser`,
      headers: {
        Authorization: token,
      },
    });
    dispatch(setCurrentUser(respData.data.result));
    return true;
  } catch (err) {
    return false;
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const forgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/admin/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/forgotPassword`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/Login");
};

export const resetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/admin/resetPassword`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const AddCms = async (data) => {
  try {
      // console.log(data,'data')
      let respData = await axios({
          'method': 'post',
          'url': `${config.API}/cms`,
          data
      });
      // console.log(respData,'respdddddddddddddddddddddd')
      return {
          status: "success",
          loading: true,
          messages: respData.data.message,
          result: respData
      }
  }
  catch (err) {
      return {
          status: "failed",
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const getCms = async()=>{
  try {
    // console.log(data,'data')
    let respData = await axios({
        'method': 'get',
        'url': `${config.API}/cms`,
        
    });
    // console.log(respData,'respdddddddddddddddddddddd')
    return {
        status: "success",
        loading: true,
        messages: respData.data.message,
        result: respData
    }
}
catch (err) {
    return {
        status: "failed",
        loading: false,
        error: err.response.data.errors
    }
}
}
export const getsinglecms = async (id) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getcmsbyid/` + id,
    
    });
    return {
      status: "success",
        loading: true,
        messages: respData.data.message,
        result: respData
    };
  } catch (err) {
    return {
      status: "failed",
        loading: false,
        error: err.response.data.errors
    };
  }
};
export const updatecmsbyid = async (data) => {
  try {
      // console.log(data,'data')
      let respData = await axios({
          'method': 'put',
          'url': `${config.API}/cms`,
          data
      });
      // console.log(respData,'respdddddddddddddddddddddd')
      return {
          status: "success",
          loading: true,
          messages: respData.data.message,
          result: respData
      }
  }
  catch (err) {
      return {
          status: "failed",
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const deletecms = async (id) => {
  //console.log('yes')
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API}/deletecmsbyid/` + id,
    
    });
    return {
      status: "success",
        loading: true,
        messages: respData.data.message,
        result: respData
    };
  } catch (err) {
    return {
      status: "failed",
        loading: false,
        error: err.response.data.errors
    };
  }
};
export const AddSettings = async (data) => {
  try {
      // console.log(data,'data')
      let respData = await axios({
          'method': 'post',
          'url': `${config.API}/add-settings`,
          data
      });
      // console.log(respData,'respdddddddddddddddddddddd')
      return {
          status: "success",
          loading: true,
          messages: respData.data.message,
          result: respData
      }
  }
  catch (err) {
      return {
          status: "failed",
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const getSettings = async (data) => {
  try {
      // console.log(data,'data')
      let respData = await axios({
          'method': 'get',
          'url': `${config.API}/get-settings`,
          data
      });
      // console.log(respData,'respdddddddddddddddddddddd')
      return {
          status: "success",
          loading: true,
          messages: respData.data.message,
          result: respData
      }
  }
  catch (err) {
      return {
          status: "failed",
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const UpdateSettings = async (data) => {
  try {
      // console.log(data,'data')
      let respData = await axios({
          'method': 'put',
          'url': `${config.API}/update-settings`,
          data
      });
      // console.log(respData,'respdddddddddddddddddddddd')
      return {
          status: "success",
          loading: true,
          messages: respData.data.message,
          result: respData
      }
  }
  catch (err) {
      return {
          status: "failed",
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const getContactlist = async () => {

  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/getcontact-list`,
    });
    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};