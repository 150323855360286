import React, { useEffect, useState,Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getBidslist,getAllcategory , deletecategory } from './../../actions/admin';
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import CardHeader from "components/Card/CardHeader.js";
import * as moment from "moment";
import ReactDatatable from '@ashvin27/react-datatable';
import config from 'lib/config';
import { toast } from "react-toastify";
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }

const rows = [
    // getData('')
    //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
    //   createData('Donut', 452, 25.0, 51, 4.9),
];


const headCells = [
    { id: 'category', numeric: false, disablePadding: true, label: 'Token Bid Amount' },
    { id: 'bidaddress', numeric: true, disablePadding: false, label: 'Token Bid Address' },
    { id: 'tokencount', numeric: true, disablePadding: false, label: 'Token Count' },
    { id: 'tokendate', numeric: true, disablePadding: false, label: 'Date' },


];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes             = useStyles();
    const customStyles        = customStyle();
    const history             = useHistory()
    const classesSearch       = useToolbarStyles();
    const [data, setData]     = useState(0);
    const [orderBy, setOrderBy]   = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage]         = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userDetails, setUser] = useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [typingTimeout, setTypingTimeout] = useState(0)
    const[responsive,setresponsive]            = useState(true);

    function editR(id) 
    {
        if(id!='') {
            history.push("/categoryedit/"+id)
        }
        
    }
    function lock(){

    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        const self = this;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setSearchQuery(value)
        setTypingTimeout(setTimeout(function () {
            getAll({ 'search': value });
        }, 1000))
    }

    const handleRequestSort = (event, property) => {
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        getAll();
    }, [])
    const getAll = async (search = {}) => {
        let filterData = search;
        if (filterData.search == '') {
            filterData = {}
        }
        // console.log('result=================dddddddddddddddddddddddddddddddddddd==', filterData);
        // var res = await getAllcategory(filterData);
        // console.log('result===================', res);
        // setUser(res.result.data);
        // console.log('result of')
        var test = await getBidslist();
        if(test && test.userValue && test.userValue.categoryData && test.userValue.categoryData.length>0){
         setUser(test.userValue.categoryData);
        }else{
         setUser("") 
        }
    }


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleDelete = async (iid) => {
        try {
            var data={
                id:iid
            }
            const res = await deletecategory(data);
            console.log(res,"================================")
            toast.warning(res.result.data.message, toasterOption);
            getAll();
        } catch (err) { }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const columns = [
        {
          key: "tokenBidAddress",
          text: "Bidder Address",
          className: "tokenName",
          align: "left",
          sortable: false
        },
        {
          key: "tokenBidAmt",
          text: "Bidding Amount",
          className: "tokenOwner",
          align: "left",
          sortable: false,
        //   cell: record =>
        //   <div><p>{halfAddrShow(record.tokenOwner)}</p></div>
        },
        {
            key: "tokenBidAmt",
            text: "Info Url",
            className: "tokenOwner",
            align: "left",
            sortable: false,
            cell: record =>
            <div><a href={config.Nfturl+record.tokenCounts} target="_blank">{config.Nfturl+record.tokenCounts}</a></div>
          },
        {
            key: "date",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: false,
            cell:record=>
                // console.log(record.date,"date")
               <div><p>{ moment(record.date).format('MMMM,Do YYYY, hh:mm A')}</p></div>
          },
        //   {
        //     key: "action",
        //     text: "Action",
        //     className: "action",
        //     width: 200,
        //     align: "left",
        //     sortable: false,
        //     cell: record => {
        //       console.log(record, 'recordssssss');
        //       const checkin = record.status;
        //       console.log(checkin, 'checkineeed');
        //       if (checkin == '0') {
        //         var lockVal = 'fa fa-lock';
        //       } else {
        //         var lockVal = 'fa fa-unlock';
        //       }
        
        //       return (
        //         <Fragment>
        //           <button
        //             data-toggle="modal"
        //             data-target="#update-template-modal"
        //             className="btn btn-primary btn-sm"
        //             onClick={() => editR(record._id)}
        //             style={{ marginRight: '5px' }}>
        //        <i class="fa fa-eye" aria-hidden="true"></i>
        //           </button>
        //           <button
        //             className="btn btn-danger btn-sm mr-1"
        //             onClick={() => handleDelete(record._id)}
        //             >
        //             <i className="fa fa-trash"></i>
        //           </button>
        //         </Fragment>
        //       );
        //     }
        //   },
      ];
    const configdata = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };
    function pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <ReactDatatable
                   responsive={responsive}
                   config={configdata}
                   records={userDetails}
                   columns={columns}
                    onPageChange={pageChange()}
                    />
            </Paper>
        </div>
    );
}