import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";


import { Editor } from '@tinymce/tinymce-react';
import { toastAlert } from '../../lib/toastAlert'

import { getsinglecms,updatecmsbyid } from '../../actions/admin'


const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  textDanger: {
    color: "#f32828",
  },
});

const initialFormValue = {
  "id": "",
  'identifier': "",
  'title': "",
  'content': "",
}
const useStyles = makeStyles(styles);

const EditCurrency = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const {
    id,
    identifier,
    title,
    content,
  } = formValue


  // function

  const onChange = (e) => {
    e.preventDefault();
   // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    console.log(formValue);
    //setValidateError(formData)
  }

 

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let reqData = {
        id:params.id,
        identifier,
        title,
        content
      }
      console.log(reqData,'reqData')
      const { status, loading, messages, error } = await updatecmsbyid(reqData);
      if (status == 'success') {
        toastAlert('success', messages, 'EditCMS')
        history.push('/CmsList')
      } else {
        setValidateError(error);
      }
    }
    catch (err) {
      console.log(err,'error')
    }
  }


  const fetchSingleCurrency = async () => {
    try {
      const { status, loading, error, result } = await getsinglecms(params.id);
      if (status == 'success') {
        console.log("ressssssssss", result)
        var tempdata = result.data.data
        let data = {
          'identifier': tempdata.identifier,
          'title': tempdata.title,
          'content': tempdata.content,
        }
        setFormValue(data)
      } else {
        setValidateError(error);
       }
    } catch (err) { 
      console.log(err,'error')
    }
  }




  const onchangeeditor = (e) => {
    let formData = { ...formValue, ...{ ["content"]: e } }
    setFormValue(formData)
    // console.log(formValue);
  }


  useEffect(() => {
    fetchSingleCurrency();
  }, [])
console.log(params.id,'paaa')
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form method="POST" className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Update CMS</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                {identifier=='home'&&(
 <GridItem xs={12} sm={12} md={4}>
 <CustomInput
   labelText="Identifier"
   id="identifier"
   value={identifier}
   onChange={onChange}
   formControlProps={{
     fullWidth: true
   }}
   inputProps={
    { readOnly: true, }
  }
 />
 {
   validateError.identifier && <span className={classes.textDanger}>{validateError.identifier}</span>
 }
</GridItem>
                )}
                 {identifier!='home'&&(
                    <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Identifier"
                      id="identifier"
                      value={identifier}
                      onChange={onChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                      
                    />
                    {
                      validateError.identifier && <span className={classes.textDanger}>{validateError.identifier}</span>
                    }
                  </GridItem>
                 )}
{identifier=='home'&&(
  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Title"
                      id="title"
                      value={title}
                      onChange={onChange}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={
                        { readOnly: true, }
                      }
                    />
                    {
                      validateError.title && <span className={classes.textDanger}>{validateError.title}</span>
                    }
                  </GridItem>
)}
                 {identifier!='home'&&(
                   <GridItem xs={12} sm={12} md={4}>
                   <CustomInput
                     labelText="Title"
                     id="title"
                     value={title}
                     onChange={onChange}
                     formControlProps={{
                       fullWidth: true
                     }}
                   />
                   {
                     validateError.title && <span className={classes.textDanger}>{validateError.title}</span>
                   }
                 </GridItem>
                 )} 

                 



                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <p>Content:</p>
                    <Editor apiKey='j2crzkts7ankhknggcx4ku2xkuiaxsiruhwg1e01cfwvmbpq'
                      value={content}
                      onEditorChange={onchangeeditor}
                      id="content"
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo code | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
                      }}
                    />

                    {
                      validateError.content && <span className={classes.textDanger}>{validateError.content}</span>
                    }
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default EditCurrency;