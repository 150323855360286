import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import isEmpty from "../../lib/isEmpty";

import Web3 from "web3";
import "@metamask/legacy-web3";

import { addLotterySettings, getAddress } from "./../../actions/admin";

import lotteryABI from "../../ABI/Lottery.json";

import config from "../../lib/config";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  operator: "",
  treasury: "",
  injector: "",
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [address, setAddress] = useState("");
  const [loading,setloading] = useState(false);

  useEffect(() => {
    getaddress();
  }, []);

  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  const { operator, injector, treasury } = formValue;

  const handleFormSubmit = async (e) => {
    console.log("saran");
    setloading(true)
    e.preventDefault();
    if (!window.ethereum) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setloading(false)
      return false;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setloading(false)
      return false;
    }
    console.log(
      config.ContractAddress,
      "config.ContractAddressconfig.ContractAddressconfig.ContractAddressconfig.ContractAddress"
    );
    const Contract = new web3.eth.Contract(lotteryABI, config.ContractAddress);
    if (
      !web3.utils.isAddress(operator) ||
      !web3.utils.isAddress(treasury) ||
      !web3.utils.isAddress(injector)
    ) {
      toast.warning("Check Your address Format", toasterOption);
      setloading(false)
      return false;
    }
try{
    var result = await Contract.methods
      .setOperatorAndTreasuryAndInjectorAddresses(operator, treasury, injector)
      .send({ from: currAddr });

    var transactionHash = "";
    if (result.transactionHash) {
      transactionHash = result.transactionHash;
    } else {
      transactionHash = result;
    }

    let reqData = {
      operator,
      treasury,
      injector,
      transactionHash,
    };

    let { error } = await addLotterySettings(reqData);

    if (isEmpty(error)) {
      toast.success("Settings updated successfully", toasterOption);
      setloading(false)
    } else {
      setValidateError(error);
      setloading(false)
    }
  }catch(err){
    console.log(err)
    setloading(false)
    toast.warning("user Rejected", toasterOption);
    }
    console.log("error");
  };

  async function getaddress() {
    var data = await getAddress();
    console.log(data, "datadatadatadata");
    if (data && data.userValue != undefined) {
      let formdata = {};
      formdata["operator"] = data.userValue.operator;
      formdata["treasury"] = data.userValue.treasury;
      formdata["injector"] = data.userValue.injector;
      setFormValue(formdata);
    }
  }
console.log(loading,"loading")
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Update Lottery Settings
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Update Lottery Settings
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="operator"
                      onChange={onChange}
                      id="operator"
                      value={operator}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.operator && (
                      <span className="text-danger">
                        {validateError.operator}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="treasury"
                      onChange={onChange}
                      id="treasury"
                      value={treasury}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.treasury && (
                      <span className="text-danger">
                        {validateError.treasury}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="injector"
                      onChange={onChange}
                      id="injector"
                      value={injector}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.injector && (
                      <span className="text-danger">
                        {validateError.injector}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {/* <Select
                        onChange={handleChange}
                        options={categorytype}
                        label="select type"
                         formControlProps={{
                        fullWidth: true
                      }}
                      /> */}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button disabled={loading} color="primary" type="submit">
               {loading&& <i class="fa-2x fa-solid fa-spinner fa-spin-pulse"></i>}  Add
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
