let key = {};
if (process.env.NODE_ENV === "production") {
  console.log("Set Production Config");
  const API_URL         = 'https://nftapi.spideybarter.com/v2';
  const frontUrl        = 'https://controls.spideybarter.com/';
  const Nfturl          = "https://nft.spideybarter.com/";
  const ContractAddress = "0x49e8749FE9E8C7a2863fAe5154FeEd225c570f56";
  const adminAddress = "0xED5e6B22a1d9c1d9246a11b294FD65F75acC9806";

  // const API_URL = "https://api.spideybarter.com/v2";
  // const frontUrl = "https://spideybarter.com/spideyadmin";
  // const Nfturl = "https://spideybarter.com/SpideyBarter-NFT/info/";
  // const ContractAddress = "0x4Eb4FAa37167d21244aA6D8F3c3F1F7736688E6D";

  key = {
    secretOrKey: "",
    Recaptchakey: "",
    frontUrl: frontUrl,
    Nfturl: Nfturl,
    API: `${API_URL}`,
    ContractAddress: ContractAddress,
    adminAddress: adminAddress,
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost:2056/v2";
  const frontUrl = "http://localhost:3000";
  const Nfturl = "https://spideybarter.com/SpideyBarter-NFT/info/";
  const ContractAddress = "0xEe03543492f2B03FFD21Ab9D310fA8140856B974";
  const adminAddress = "0x3778EcF0b729ecd4DdcA96e2D9f588314Ad73F6D";
  key = {
    secretOrKey: "",
    Recaptchakey: "",
    Nfturl: Nfturl,
    frontUrl: frontUrl,
    // API:`${API_URL}:2086`,
    API: `${API_URL}`,
    ContractAddress: ContractAddress,
    adminAddress: adminAddress,
  };
}

export default key;

//old
// let key = {};
// if (process.env.NODE_ENV === "production") {
//   console.log("Set Production Config");
//   // const API_URL = 'http://212.71.233.123';
//   // const frontUrl = 'http://212.71.233.123/spideywqwedkk';

//   const API_URL = "https://api.spideybarter.com/v2";
//   const frontUrl = "https://spideybarter.com/spideyadmin";
//   const ContractAddress = "0xB0633070cFf8014a959Cc512DCBbfCf97FcEF739";

//   key = {
//     secretOrKey: "",
//     Recaptchakey: "",
//     frontUrl: frontUrl,
//     Nfturl:Nfturl,
//     API: `${API_URL}`,
//     ContractAddress: ContractAddress,
//   };
// } else {
//   console.log("Set Development Config");
//   const API_URL = "http://localhost:2056/v2";
//   const frontUrl = "http://localhost:3000";
//   const ContractAddress = "0xB0633070cFf8014a959Cc512DCBbfCf97FcEF739";
//   key = {
//     secretOrKey: "",
//     Recaptchakey: "",
//     Nfturl:Nfturl,
//     frontUrl: frontUrl,
//     // API:`${API_URL}:2086`,
//     API: `${API_URL}`,
//     ContractAddress: ContractAddress,
//   };
// }

// export default key;
