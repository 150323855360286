import React, { Fragment, useEffect, useState } from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import { toastAlert } from '../../lib/toastAlert'
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import {getCms,deletecms }from "../../actions/admin"



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    }
}));




export default function EnhancedTable() {
    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [cmsdetails, setcmsdetails] = useState([]);
  

   
    const getAllcms = async () => {
        var res = await getCms();
        console.log(res,'ress')
        if (res && res.result && res.result.data && res.result.data.data) {
            setcmsdetails(res.result.data.data);
            
        }

    }   

    const handleDelete = async (iid) => {
        try {
            var data = {
                id: iid
            }
            console.log(data,'ddd')
            if (window.confirm("Do you really delete?")){
                const res = await deletecms(iid);
            
                getAllcms();
                console.log(res, "===ress=============================")
                toastAlert('success', res.messages, 'AddCms')
            }
            
    
        } catch (err) { }
    }
    
    useEffect(() => {
        getAllcms();
  
    }, [])
 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    return (
        <div className={classes.root}>
            

            <Paper className={classes.paper}>
              
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>CMS List</h4>
                            <Link to={'/Addcms'} className={classes.btnPrimary}>Add CMS</Link>
                        </div>
                    </CardHeader>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                          
                            aria-label="enhanced table"
                        >
                          {}
                            <TableBody>
                                {cmsdetails && cmsdetails.map((row, index) => {
                                    console.log(cmsdetails[index].identifier,'cmsdetails')
                                    return (
                                        <TableRow>
                                            <TableCell align="center">{row.identifier}</TableCell>
                                            <TableCell align="center">{row.title}</TableCell>
                                           

                                            <TableCell align="center">
                                                
                                                {cmsdetails[index].identifier=='home'&&(
                                                    <Link to={"/Editcms/" +row._id} ><EditIcon  style={{ color: "#109ebf" }} /></Link>
                                                )}
                                                 {cmsdetails[index].identifier!='home'&&(
                                                     <Fragment>

                                                    
                                                    <Link to={"/Editcms/" +row._id} ><EditIcon  style={{ color: "#109ebf" }} /></Link>
                                                    <Link onClick={() => handleDelete(row._id)}>
                                                    <DeleteIcon style={{ color: "#109ebf" }} />
                                                    
                                                </Link>
                                                </Fragment>
                                                )}
                                              
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                       
                        component="div"
                        count={cmsdetails && cmsdetails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Card>
            </Paper>

        </div>
    );
}