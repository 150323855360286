import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import List from '@material-ui/icons/List';

import lotSettings from "views/Lottery/addSettings";
import startLottery from "views/Lottery/startLottery";
import LotteryList from "views/Lottery/LotteryList";
import TokenList from "views/Token/TokenList";
import TokenView from "views/Token/Tokenview";
import BiddingList from "views/Bidding/BiddingList";
import Cheatedlist from "views/Cheated/Cheatedlist.js";
import CheatedView from  "views/Cheated/CheatedView.js";
import Donationlist from "views/Donation/Donationlist.js";
import DonationView from "views/Donation/DonationView.js";
import Scancompanylist from "views/Scancompany/Scancompanylist.js";
import Scancompanyview from "views/Scancompany/Scancompanyview.js";
import feedbacklist from "views/feedback/feedback.js"; 
import contactlist from "views/contact/contact.js"
import FaqAdd from 'views/Faq/Faqadd';
import FaqList from 'views/Faq/FaqList';
import FaqEdit from 'views/Faq/Faqedit'
import DashboardPage from "views/Dashboard/Dashboard.js";
import AdjustIcon from "@material-ui/icons/Adjust";
import CmsList from 'views/Cms/Cmslist'
import Addcms from 'views/Cms/Cmsadd'
import Editcms from 'views/Cms/cmsedit'
import Homepage from 'views/Cms/homepagecontent'
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
    menu: "/sidemenu"
  },
  {
    path: "/biddingList",
    name: "Bidding List",
    icon: Dashboard,
    component: BiddingList,
    layout: "/admin",
    menu: "/sidemenu",
  },
  {
    path: "/donation",
    name: "Donation Management",
    icon: List,
    component: Donationlist,
    layout: "/admin",
    menu: "/sidemenu"
  },
  {
    path: "/donatelist/:userId",
    icon: Person,
    icon: List,
    component: DonationView,
    layout: "/admin",
    menu: ""
  },
  {
    path: "/faqadd",
    name: "Faq add",
    icon: List,
    component: FaqAdd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/faqList",
    name: "Faq List",
    icon: List,
    component: FaqList,
    layout: "/admin",
    menu : "/sidemenu"
  },
  {
    path: "/faqedit/:faqId",
    name: "Faq edit",
    icon: List,
    component: FaqEdit,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/feedbacklist",
    name: "Feed back",
    icon: List,
    component: feedbacklist,
    layout: "/admin",
    menu: "/sidemenu"
  },
  {
    path: "/lotSettings",
    name: "Lottery Settings",
    icon: Dashboard,
    component: lotSettings,
    layout: "/admin",
    menu: "/sidemenu",
  },
  {
    path: "/LotteryList",
    name: "Lottery List",
    icon: Dashboard,
    component: LotteryList,
    layout: "/admin",
    menu: "/sidemenu",
  },
  // {
  //   path: "/ponzi",
  //   name: "Ponzi Management",
  //   icon: List,
  //   component: Cheatedlist,
  //   layout: "/admin",
  //   menu: "/sidemenu"
  // },
  {
    path: "/ViewCheated/:userId",
    icon: Person,
    icon: List,
    component: CheatedView,
    layout: "/admin",
    menu: ""
  },
  {
    path: "/startLottery",
    name: "Start Lottery",
    icon: Dashboard,
    component: startLottery,
    layout: "/admin",
    menu: "",
  },
  
  {
    path: "/scancompany",
    name: "Scam Company Management",
    icon: List,
    component: Scancompanylist,
    layout: "/admin",
    menu: "/sidemenu"
  },
  {
    path: "/tokenList",
    name: "Token List",
    icon: Dashboard,
    component: TokenList,
    layout: "/admin",
    menu: "/sidemenu",
  },
   {
    path: "/TokenView/:userId",
    name: "Token View",
    icon: Dashboard,
    component: TokenView,
    layout: "/admin",
    menu: "",
  },
  {
        name: "CMS",
        path: "/CmsList",
        component:CmsList,
        icon: AdjustIcon,
        layout: "/admin",
    menu: "/sidemenu",

  },
  {
    name: "Add cms",
    path: "/Addcms",
    component:Addcms,
    icon: AdjustIcon,
    layout: "/admin",
menu: "",

},
{
  name: "Edit cms",
  path: "/Editcms/:id",
  component:Editcms,
  icon: AdjustIcon,
  layout: "/admin",
menu: "",

},
{
  name: "Add homepageSettings",
  path: "/homepage-content",
  component:Homepage,
  icon: AdjustIcon,
  layout: "/admin",
  menu: "/sidemenu",
},
{
  path: "/contact",
  name: "Contact Form",
  icon: List,
  component: contactlist,
  layout: "/admin",
  menu: "/sidemenu"
},
];

export default dashboardRoutes;
