import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'

import { Editor } from '@tinymce/tinymce-react';



import { AddCms } from '../../actions/admin';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  textDanger: {
    color: "#f32828",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'identifier': "",
  'title': "",
  'content': "",
  
}

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
 

 
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { userId } = useParams();

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    // console.log(formValue);
  }

  const {
    identifier,
    title,
    content,
  } = formValue

  

  const onchangeeditor = (e) => {
    let formData = { ...formValue, ...{ ["content"]: e } }
    setFormValue(formData)
    // console.log(formValue);
  }

  

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let reqData = {
        identifier,
        title,
        content
      }
      console.log(reqData,'reqData')

      const { status, loading, messages, error } = await AddCms(reqData);
      console.log(status,'status')
     
      if (status == 'success') {
        toastAlert('success', messages, 'AddCms')
        setFormValue(initialFormValue)
        setValidateError({})
        history.push('/CmsList')
      } else {
        setValidateError(error);
      }
    } catch (err) {
      console.log(err, 'errr')
    }

  }

 

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New CMS</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      labelText="identifier"
                      id="identifier"
                      value={identifier}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      validateError.identifier && <span className={classes.textDanger}>{validateError.identifier}</span>
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      labelText="Title"

                      id="title"
                      value={title}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                      validateError.title && <span className={classes.textDanger}>{validateError.title}</span>
                    }
                  </GridItem>
                 

                </GridContainer>
              

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <p >Content:</p>
                    {/* <Editor apiKey='j2crzkts7ankhknggcx4ku2xkuiaxsiruhwg1e01cfwvmbpq' */}
                    <Editor apiKey='y6ndb4wh9x6tttr6nzjo90vgtu9m71dcslrbgu9u9rj4mmsj'
                      onEditorChange={onchangeeditor}
                      id="content"
                      init={{
                        height: 500,
                        menubar: false,
                        //plugins: ' image code code print preview fullpage  searchreplace autolink directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount ',
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | link image | code |formatselect | bold italic strikethrough  | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment |media',
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'file image media',
        image_advtab: true,
        media_live_embeds: true,
        // file_picker_callback: function (cb, value, meta) {
        //   var input = document.createElement('input');
        //   input.setAttribute('type', 'file');
        //   input.setAttribute('accept', 'image/* audio/* video/*');
      
        //   /*
        //     Note: In modern browsers input[type="file"] is functional without
        //     even adding it to the DOM, but that might not be the case in some older
        //     or quirky browsers like IE, so you might want to add it to the DOM
        //     just in case, and visually hide it. And do not forget do remove it
        //     once you do not need it anymore.
        //   */
      
        //   input.onchange = function () {
        //     var file = this.files[0];
      
        //     var reader = new FileReader();
        //     reader.onload = function () {
        //       /*
        //         Note: Now we need to register the blob in TinyMCEs image blob
        //         registry. In the next release this part hopefully won't be
        //         necessary, as we are looking to handle it internally.
        //       */
        //       var id = 'blobid' + (new Date()).getTime();
        //       var blobCache =  Editor.activeEditor.editorUpload.blobCache;
        //       var base64 = reader.result.split(',')[1];
        //       var blobInfo = blobCache.create(id, file, base64);
        //       blobCache.add(blobInfo);
      
        //       /* call the callback and populate the Title field with the file name */
        //       cb(blobInfo.blobUri(), { title: file.name });
        //     };
        //     reader.readAsDataURL(file);
        //   };
      
        //   input.click();
        // },
        file_picker_callback: function (callback, value, meta) {
          if (meta.filetype == 'image') {
            var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/* audio/* video/*');
              input.click();
              input.onchange = function () {
                  var file = input.files[0];
                  var reader = new FileReader();
                  reader.onload = function (e) {
                      
                      callback(e.target.result, {
                          alt: file.name
                      });
                  };
                  reader.readAsDataURL(file);
              };
          }
      },
        paste_data_images: true,
                      }}
                    />

                    {
                      validateError.content && <span className={classes.textDanger}>{validateError.content}</span>
                    }
                  </GridItem>
                </GridContainer>

              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Add</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
