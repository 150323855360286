import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { gettokencount,getdonationcount } from '../../actions/admin';

import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [tokenCount, settokenCount] = useState(0);
  const [bidderscount, setbiddersCount] = useState(0);
  const [donationcount, setdonationCount] = useState(0);

  const [ref, setref] = useState(0);
  const [pur, setpur] = useState(0);

 
  useEffect(() => {
    //logout(history)
    gettokencounts();
    getDonationcount();
    }, [])

   const gettokencounts =async () => {
      //   var test = await gettokencount();

      //    if (test&&test.userValue&&test.userValue.userValue) {
      //      if(test.userValue.userValue.length>0){
      //       settokenCount(test.userValue.userValue.length);
      //      }
        
      // }
    }

    const getDonationcount =async () => {
        var {result} = await getdonationcount();
         if (result&&result.data) {
          console.log("result:",result)
        setdonationCount(result.data);
      }
    }

  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total NFTs</p>
              <h3 className={classes.cardTitle}>
                {donationcount} <small>NFTs</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              {/*  <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>*/}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
       
       {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Donation</p>
              <h3 className={classes.cardTitle}>{bidderscount} <small>Bidders</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                
              </div>
            </CardFooter>
          </Card>
        </GridItem>*/}
      </GridContainer>

       
    </div>
  );
}
